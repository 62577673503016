.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9012;
  width: inherit;
  outline: 0;
  max-height: 95%;
  overflow-y: auto;
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(51, 37, 26, 0.1);
  /* Background blur */

  backdrop-filter: blur(3px);
  z-index: 9011;
}
.styledModal {
  z-index: 9010;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 8px;
}
.header {
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  padding: 24px;
}
.headerText {
  align-self: center;
  font-family: var(--header-font-family);
  font-size: 32px;
}
.closeButton {
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;
}
.closeButton:hover {
  cursor: pointer;
}
.content {
  padding: 10px;
}
