.background {
  background-color: var(--whiteish);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.container {
  background-color: white;
  width: 800px;
  margin-top: 250px;
  margin-bottom: 400px;
  padding: 32px;
  border-radius: 4px;
  border: 1px solid var(--neutral-10);
}

.pageTitle {
  font-size: 32px;
  font-family: var(--header-font-family);
}

.sectionHeader {
  font-size: 26px;
  font-family: var(--header-font-family);
}
