.colors {
  display: flex;
  flex-flow: row nowrap;
}

.colorCell {
  padding: 8px;
  border-left: 1px solid var(--neutral-10);
}

.colorContainer {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral-10);
}

.colorContainer > div {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.labelRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
}

.label {
  font-family: var(--small-font-family);
  color: var(--light-brown);
  width: 36px;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 4px;
}

.label + .label {
  margin-left: 4px;
}

.index {
  font-family: var(--small-font-family);
  color: var(--light-brown);
  font-size: 11px;
  line-height: 14px;
  width: 52px;
  text-align: right;
  padding-right: 8px;
}

.amountRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.amountRow + .amountRow {
  border-top: 1px solid var(--neutral-10);
}

.spacer {
  flex-grow: 1;
  flex-shrink: 1;
}

.amountInput {
  width: 30px;
  height: 26px;
  border: 1px solid var(--neutral-10);
  font-family: var(--text-font-family);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--dark-brown);
  border-radius: 4px;
}

.amountInput + .amountInput {
  margin-left: 4px;
}
