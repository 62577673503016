/* COLOR */
.color {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}

.color:hover {
  background-color: var(--neutral-10);
}

.color + .color {
  border-top: 1px solid var(--neutral-10);
}

.color div + div {
  margin-left: 16px;
}

/* PREVIEW */

.preview {
  height: 20px;
  width: 20px;
  border: 2px solid white;
  border-radius: 2px;
  background-color: white;
}

.previewInner {
  border-radius: 1px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.colorName {
  color: var(--darkest-brown);
  font-family: var(--text-font-family);
  font-size: 14px;
  line-height: 20px;
  flex-grow: 1;
}

.selector {
  width: 12px;
  height: 12px;
  box-shadow: 0 0 0 2px var(--dark-brown);
  border-radius: 1px;
  box-sizing: border-box;
  margin: 2px 2px 2px 16px;
}

.selector.selected {
  box-shadow: 0 0 0 2px var(--positive);
  border: 1px solid var(--white-ish);
  background-color: var(--positive);
}
