.menubutton {
  flex: 1;
    padding: 0px 0px;
    border: none;
    border-radius: 0 !important;
    width: auto;
    border-top: 3px solid #00000000;
    border-bottom: 3px solid #00000000;
    margin-left: 20px;
}

  .active {
    border-bottom: 3px solid var(--contrast);
  }

  .showHover {
    visibility: hidden;
  }

  .patternbutton:hover > .showHover {
    visibility: visible;
  }