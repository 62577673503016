.sidebar {
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container {
  width: 100%;
  height: 100%;
  background: #faf6f2;
}

.innerBtnContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0.75rem;
  justify-content: flex-end;
  
}

.linkContainer {
  display: flex;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  flex-direction: column;
  gap: 0.75rem;
}

.link {
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
}
