@media print {
  .header {
    opacity: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  padding: 16px 24px;
  background: white;
}

.image {
  width: 2.22rem;
}
