.confirmationButtons {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
}
.currentStatus {
  border: 1px solid var(--darkest-brown);
}
.message {
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
}
.button {
  margin: 8px;
  width: 15ch;
}
.logBox {
  height: 100px;
  width: 100%;
  overflow: auto;
  font-size: 0.5rem;
  border-top: 1px solid var(--neutral-10);
  justify-content: center;
  align-items: center;
}
.logBoxList {
  width: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: column-reverse;
}
.logBoxList dt:nth-child(odd) {
  /* background-color: var(--neutral-20); */
}
.logBoxList dt:nth-child(even) {
  background-color: var(--neutral-10);
}
