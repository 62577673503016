.colorImage {
  width: 40px;
  height: 40px;
}

.colorRow {
  display: flex;
  align-items: center;
}

.colorText {
  flex-grow: 1;
}

.colorRow > * + * {
  margin-left: 8px;
}

.colorRow + .colorRow {
  margin-top: 16px;
}

.buttonRow {
  margin-bottom: 24px;
}

.buttonRow > * + * {
  margin-left: 8px;
}

.warningButton {
  color: var(--contrast);
}
