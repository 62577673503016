.bottom {
  position: absolute;
  margin-top: -12px;
  border-left: 1px solid var(--contrast);
  border-right: 1px solid var(--contrast);
  border-bottom: 1px dashed var(--contrast);
  z-index: -1;
}

.right {
  border-right: 1px dashed var(--contrast);
  border-top: 1px solid var(--contrast);
  border-bottom: 1px solid var(--contrast);
  position: absolute;
  z-index: -1;
}

