.ctaButton {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 16px;
  border: none;
  border-radius: 5px;
  background-color: var(--positive);
  color: var(--whiteish);
  text-align: center;
}

.ctaButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(80%);
  background-size: cover;
  background-image: url("yarn-green.jpg");
  border-radius: 5px;
}

.ctaButtonContent {
  color: inherit;
  position: relative;
  margin: 0;
  letter-spacing: 0.1ch;
}
