.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 106000;
}

.inlineContainer {
  margin-top: 24px;
  margin-left: 10vw;
  margin-bottom: 24px;
}

.button {
  margin-right: 16px;
}

.background {
  width: 100%;
  height: 100%;
  background-color: var(--light-brown);
  opacity: 50%;
}

.modal {
  position: relative;
  padding: 100px;
  height: 600px;
  width: 400px;
  margin: calc(-50vh - 400px) calc(50vw - 300px);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--medium-brown);
}

.tableButtonContainer {
  padding: 20px;
}

.tableButtonContainer button + button {
  margin-left: 20px;
}

.active {
  background-color: var(--light-brown);
  border-color: var(--light-brown);
  color: white;
}

.entry {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.checkbox {
  width: 28px;
  height: 28px;
  border: 4px solid white;
  border-radius: 2px;
  box-shadow: 0 0 0 2px var(--whiteish);
  margin: 2px;
}

.checkbox:hover {
  background-color: var(--whiteish);
}

.checkbox.checked {
  background-color: var(--positive);
  box-shadow: 0 0 0 2px var(--positive);
}

.checkbox:hover.checked {
  opacity: 0.8;
}
