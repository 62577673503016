.cover {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.spinnerContainer {
  width: 100px;
  height: 100px;
  padding-bottom: 100px;
}

@keyframes spinner-pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  to {
    transform: scale(1);
  }
}

.spinner {
  width: 100%;
  height: 100%;
  animation: spinner-pulse 4s infinite;
}
