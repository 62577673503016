.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
}

.element {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  height: 66px;
}

.element + .element {
  border-top: 2px solid var(--neutral-10);
  height: 68px;
}

.element button {
  color: var(--neutral-40);
  background: none;

  border: none;
  border-radius: 0;
  border-top: 1px solid var(--neutral-10);
  border-bottom: 1px solid var(--neutral-10);

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  width: 40px;
  height: 32px;
}

.element button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  border-left: 1px solid var(--neutral-10);
}

.element button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  border-right: 1px solid var(--neutral-10);
}

.element button:hover {
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
  background: none;
}

.element button.selected {
  cursor: default;

  border-radius: 4px;
  border: 1px solid var(--darkest-brown);
  color: var(--darkest-brown);
}
