.container {
  position: relative;
  display: inline-block;
}

.buttonContainer {
  padding: 4px;
  cursor: pointer;
}

.optionContainer {
  z-index: 20;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  padding: 4px;
  overflow: hidden;
  border: 1px solid var(--neutral-10);
  display: flex;
  flex-direction: column;
}

.option {
  color: var(--darkest-brown);
  padding: 4px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.option:hover {
  background-color: var(--neutral-10);
}
