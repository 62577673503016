.wrapper {
  margin-right: 5vw;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 24px;
}

.imgix {
  max-width: 30vw;
  max-height: 75vh;
}

.button {
  position: absolute;
  top: 45%;
  right: -20px;
}

.bottom {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.counter {
  border: 1px solid var(--neutral-40);
  padding: 3px 10px 1px;
  border-radius: 4px;
  background: var(--whiteish);
}

.colorContainer {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  align-items: center;
  padding: 0 4px;
  background: var(--whiteish);
}

.color {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  margin: 2px;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin-right: 0;
  }

  .button {
    right: 0px;
  }

  .imgix {
    max-width: 100vw;
    max-height: 100vh;
  }

  .bottom {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 32px);
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
  }
}
