.modalButton {
  margin-left: 4px;
}

.modalContent {
  padding: 24px 16px;
}

.sizeGroup {
  display: flex;
  align-items: stretch;
  margin: 4px 0px;
}

.sizeGroup > button {
}

.sizeGroup > input {
  margin-right: 4px;
}

.addNewSize {
  width: 100%;
  margin-top: 12px;
}

.hr {
  border-top: var(--neutral-10);
}

.bottom {
  display: flex;
  justify-content: flex-end;
}
