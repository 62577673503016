.button {
  padding: 5px 10px 3px 10px;
  border: 2px solid var(--neutral-10);
  font-family: var(--small-font-family);
  background-color: var(--whiteish);
  border-radius: 4px;
  background-color: #fff;
  color: var(--dark-brown);
  font-size: inherit;
  cursor: pointer;
}

.button:disabled {
  background-color: var(--neutral-10);
  border-color: var(--neutral-10) !important;
  opacity: 0.75;
  cursor: default;
}

.button:hover {
  background-color: var(--neutral-10);
}

.positive {
  color: var(--positive);
  border-color: var(--positive);
}

.positive:hover {
  border-color: var(--positive);
  background-color: var(--whiteish);
}

.contrast {
  color: var(--contrast);
  border-color: var(--contrast);
}

.contrast:hover {
  border-color: var(--contrast);
  background-color: var(--whiteish);
}

.a {
  text-decoration: none;
  color: var(--contrast);
}
