.form {
  display: flex;
  flex-direction: column;

  width: 100%;
  align-self: center;
}

.label {

  color: var(--header-color);
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 600px) {
  .label {
    font-size: 20px;
  }
}

.textInput {
  padding: 10px;
  border: 2px solid var(--neutral-10);
  border-radius: 2px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 2px;
  color: var(--text-color);

width: 100%;
}

.loginInfo {
  color: var(--text-color);
}

.modal {
  overflow: scroll;

  z-index: 10;
  background-color: #ffffff;
  height: 100%;
  max-height: 100vh;

  border-width: 1px;
  margin-left: 30%;
  margin-right: 30%;
}

@media (max-width: 1000px) {
  .modal {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 600px) {
  .modal {
    margin-left: 3%;
    margin-right: 3%;
  }
}

.innerModal {
  margin-left: 7rem;
  margin-right: 7rem;
  margin-top: 3rem;
  margin-bottom: 5rem;

  align-self: center;
  align-content: center;


}

@media (max-width: 600px) {
  .innerModal{
    margin-left: 1rem;
  margin-right: 1rem;
  }

}
