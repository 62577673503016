.head {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  font-weight: 700;
  border-top: 2px solid var(--neutral-10);
  border-bottom: 2px solid var(--darkest-brown);
  color: var(--darkest-brown);
  padding: 0 10vw;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 3%;
}

.name {
  width: 248px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-left: 2px solid var(--neutral-10);
  padding: 16px 16px 8px 24px;
}

.label {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-weight: 400;
  border-left: 2px solid var(--darkest-brown);
  padding: 16px 16px 8px 16px;
}

.sizes {
  display: flex;
  flex-flow: row nowrap;
}

.cell {
  width: 114px;
  box-sizing: border-box;
  border-left: 2px solid var(--neutral-10);

  display: flex;
  justify-content: center;
  align-items: center;
}

.cell.unit {
  width: 116px;
  border-left: 2px solid var(--darkest-brown);
  border-right: 2px solid var(--neutral-10);
}
