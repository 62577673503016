.container {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 8px;
}

.gaugeRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.gaugeRow * + * {
  margin-left: 16px;
}

.label {
  flex-grow: 1;
}

.gaugeRow input {
  padding: 4px 8px;
  width: 128px;
}
