.variantListItem {
  padding: 0 16px;
}

.active {
  border: 1px solid var(--whiteish);
  background-color: var(--whiteish);
  border-radius: 3px;
}

.inactive {
  cursor: pointer;
  border-radius: 3px;
}

.inactive:hover {
  background-color: var(--whiteish);
}

.variantTitle {
  margin-left: 20px;
  color: var(--dark-brown);
  font-weight: 500;
  flex-grow: 1;
}

.variantInformation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.variantColors {
  display: flex;
  flex-direction: row;
}

.imageText {
  display: flex;
  align-items: center;
}

.imageContainer {
  width: 22px;
  min-width: 22px;
  height: 22px;
  border: 1px solid white;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px 16px;
  margin-left: -16px;
  width: calc(100% - 1px);
  background-color: #fff;
}

.bodyItem {
  border-top: 1px solid var(--neutral-10);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.plus .minus {
  color: var(--positive);
  font-weight: 600;
  cursor: pointer;
}

.colorText {
  color: var(--dark-brown);
  margin-left: 8px;
}

.colorPreview {
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.customYarnAmount {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  height: 32px;
  align-items: center;
}

.customYarnAmountLabel {
  background: white;
  text-align: center;
  border: 1px solid var(--neutral-10);
  margin: 0;
  width: 64px;
  padding: 4px 8px;
  border-radius: 3px;
}
