.nav {
  position: -webkit-fixed;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  /* height: 96px; */
  align-items: center;
  pointer-events: none;
}

.filled {
  background-color: white;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px;
}

.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.top button {
  pointer-events: auto;
} 

.bottom {
  margin: 0 auto;
  text-align: center;
}

.updated {
  width: 250px;
  font-weight: 500;
}

.preview {
  width: 250px;
  text-align: right;
}

.menu {
  display: flex;
  align-items: center;
}

.menu > button {
  border: none;
}

.vl {
  height: 30px;
  border-left: 1px solid var(--neutral-10);
  width: 1px;
  margin: 0 8px;
}

.menuButton {
  border: none;
}
