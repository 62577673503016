.container {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin-bottom: 100px;
}

.headerRow {
  position: sticky;
  top: 0;
  height: 2rem !important;
}

/* for sticky header */
.container tbody:nth-of-type(1) tr:nth-of-type(1) td {
  border-top: none !important;
}

/* for sticky header */
.container thead th {
  border-top: none !important;
  border-bottom: none !important;
  background-color: var(--white-ish);
  box-shadow: inset 0 2px 0 var(--neutral-20), inset 0 -2px 0 var(--neutral-20);
  border-right: 1px dotted var(--neutral-20);
  padding: 0 0 0 1rem;
}

.container .buttonWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 10px;
}

.headerCell {
  /* for sticky header */
  background-clip: padding-box;
  /* Woolit / Meta-Tech */
  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 0.7em;
  /* identical to box height, or 127% */
  letter-spacing: 0.03em;
  color: var(--darkest-brown);
}

.headerSpacing {
  border-right: 0px !important;
  width: 20%;
}

.container td div {
  /* padding-left: 10px; */
  /* width: 100%; */
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: var(--light-brown);
}

.dataCell {
  border-top: 1px solid var(--neutral-20);
  border-right: 1px dotted var(--neutral-20);
  color: var(--light-brown);
  padding-left: 10px;
  height: 4rem;
  font-size: 14px;
}

.titleContainer {
  margin: 0 20vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
