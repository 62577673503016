.container {
  background-color: var(--white-ish);
  min-height: 100vh;
}

.projectContainer {
  background-color: var(--white-ish);
}
.projectContainer table {
  border-collapse: collapse;
}
.buttonGroup {
  padding: 20px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.patternOptionWrapper {
  text-align: center;
}

.patternOptionModalButton {
  border: none;
  background: none;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px 40px;
}

.modalActionButton {
  margin-bottom: 20px;
}

