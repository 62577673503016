
.wrapper {
  margin-top: 32px;
  width: 100%;
  border-top: 1px solid var(--neutral-10);
  border-bottom: 1px solid var(--neutral-10);
}

.text {
  padding: 8px 0 6px 0;
}