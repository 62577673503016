.popover {
  position: relative;
}

.button {
  border: none;
  padding: 2px;
}

.panel {
  position: absolute;
  padding: 8px;
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
  margin-top: 4px;
  background: white;
  z-index: 200;
}
