.image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 310px;

  background-color: var(--woolitOrange);
  background-image: linear-gradient(1deg, var(--darkest-brown) 8.03%, rgba(51, 37, 26, 0.74) 19.84%, rgba(251, 139, 48, 0.00) 54.17%),
  url("./UpgradeModalContent.png");
}

.imageHeader {
  margin: 25px;
  color: #ffffff;
  font-variant-numeric: stacked-fractions;
  font-family: var(--header-font-family);
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 52.381% */
  letter-spacing: 0.42px;
}

.textContainer {
  width: 480px;
  margin: 10px 30px 60px 30px;
}

.header {
  color: var(--darkest-brown);
  font-variant-numeric: stacked-fractions;
  font-family: var(--header-font-family);
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.42px;
}

.paragraph {
  color: var(--darkest-brown);
  font-family: var(--text-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
}

.list {
  padding: 0;
  list-style: none;
  color: var(--darkest-brown);
  font-family: var(--text-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
}

.listElement {
  display: flex;
  align-items: center;
}

.listContent {
  margin-left: 12px;
}

.price {
  color: var(--darkest-brown);
  font-family: var(--text-font-family);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 117.647% */
  letter-spacing: 0.51px;
  margin-right: 30px;
}

.ctaButton {
  background-size: cover;
  background-image: url("yarn_brown.png");
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 36px;
  padding-left: 7%;
  padding-right: 7%;
  margin-top: 3%;
  margin-bottom: 3%;
  max-width: fit-content;
}

@media (max-width: 700px) {
  .ctaButton {

    padding-top: 4%;
    padding-bottom: 4%;
    border-radius: 36px;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 5%;
    margin-bottom: 5%;

    font-size: 20px;
  }
}

.seeAllLink {
  margin-top: 10px;
  color: #D65600;
  font-family: var(--text-font-family);
  font-size: 14px;
}

.closeButton {
  align-self: flex-end;
  margin: 20px;
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;
  cursor: pointer;
}
