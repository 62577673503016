.textContainer {
  display: inline-block;
  height: 100%;
  margin-bottom: 50vh;
  width: calc(50vw - 16px);
}

.textContainer > *:focus-visible {
  outline: none;
}

.controlBar {
  background: white;
  z-index: 100;
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
}

.controlBar>button {
  width: 32px;
}

.comment {
  background-color: #f5cdb3 !important;
}

.change {
  background-color: #f6c1b6 !important;
}

.changeTo {
  background-color: #c5d9c3 !important;
}

.basicPatternElement {
  padding: 2px 0;
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 3%;
  background: inherit;
  transition: background 0.3s;
  min-width: 142px;
}

.basicPatternElement:focus {
  outline: none;
}

.span:hover {
  background: var(--whiteish);
}

.nonEditable {
  cursor: pointer;
}

.paragraphBreak {
  height: 16px;
  margin-left: -86px;
  /* width: 10vw; */
  display: flex;
  align-items: center;
}

.paragraphButton {
  height: 32px;
}

.paragraphLine {
  width: 100%;
  border-color: var(--positive);
}

.headingContainer {
  display: flex;
}

.publishDateButton {
  align-self: center;
  height: 40px;
  width: 40px;
  margin-right: 20px;
}

.heading {
  font-size: 32px;
  width: 100%;
  padding: 40px 0 8px 0;
  margin: 0;
}

.subheading {
  width: 100%;
  padding: 24px 0 8px 0;
  font-size: 28px;
  margin: 0;
}

.suggestionTab {
  background: white;
  z-index: 100;
  width: 180px;
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
}

.suggestionButton {
  width: 100%;
  border: 0;
  text-align: left !important;
  padding: 8px 0 8px 12px;
}

.suggestionButton:hover {
  background: var(--whiteish);
}

.textStyleButton {
  border: 0;
  width: 32px;
  height: 32px;
}

.linkText {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.linkText>* {
  border: 0;
}

.link {
  color: var(--contrast) !important;
}

.suggestionTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--neutral-10);
}

.suggestionTop>p {
  margin: 0 12px;
}

.highlightedButton {
  background-color: var(--whiteish);
}

.headerPlaceholder {
  display: inline-block;
}

.headerPlaceholder:before {
  content: attr(data-placeholder);
  opacity: 0.5;
  position: absolute;
}

.empty {
  display: inline-block;
  opacity: 0;
}

.empty:hover {
  opacity: 1;
}

.empty:before {
  content: attr(data-placeholder);
  opacity: 0.5;
  position: absolute;
}

.positive {
  color: var(--positive);
}

.negative {
  color: var(--contrast);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.selected {
  background: var(--whiteish);
  opacity: 1;
  /* padding: 8px 16px */
}
