.modal {
  width: 320px;
  padding: 0 36px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
