.ctaButton {
    width: 100%;
  

    background-size: cover;
    background-image: url("yarn_brown.png");

    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 20px;

    
  }


