.drawerContainer,
.drawerBackground {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9001;
}

.toggleDrawer {
  position: absolute;
  z-index: 9010;
  top: 24px;
  color: var(--dark-brown);
  padding: 8px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.openDrawer {
  left: 24px;
  padding: 8px 16px;
}

.drawer {
  position: absolute;
  left: 0;
  top: 0;
  width: 25vw;
  height: 100%;
  z-index: 9005;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 20px var(--neutral-10);
  border-radius: 4px;
}

.drawerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0;
}

.divider {
  border: 0;
  border-top: 3px solid var(--neutral-10);
}

.drawerContent {
}
