.center {
  text-align: center;
}

.overflow {
  width: calc(100vw - 50px);
  margin-left: calc(-25vw + 25px);
}

.inline {
  width: calc(50vw - 16px);
}

.imgixInline {
  max-width: calc(50vw - 16px);
}

.imgixOverflow {
  max-height: 70vh;
  max-width: 80vw;
}

@media only screen and (max-width: 768px) {
  .imgix {
    max-height: 100vh;
    max-width: 100vw;
  }
  .container {
    width: 100vw;
    margin: 0;
  }
}

.buttonGroup {
  padding: 20px 0;
}
