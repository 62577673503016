.cell {
  width: 114px;
  box-sizing: border-box;
  border-left: 2px solid var(--neutral-10);
}

.element {
  padding: 16px;
}

.element + .element {
  border-top: 2px solid var(--neutral-10);
  box-sizing: border-box;
}
