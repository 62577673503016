.container {
  display: flex;
  flex-flow: row nowrap;

  padding: 8px;
  height: 40px;
}

.size {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: var(--neutral-10);
  border-radius: 2px;
}

.size.active {
  background-color: var(--positive);
}

.size+.size {
  margin-left: 4px;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  color: var(--light-brown);
  font-size: 11px;
  line-height: 14px;
}

.size.active .label {
  color: var(--whiteish);
}

.input {
  padding: 5px 8px;
  height: 36px;
  width: 89px;
  box-sizing: border-box;

  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: none;
  margin-right: 2px;
}

.container {
  display: flex;
  flex-flow: row nowrap;

  padding: 8px;
  height: 40px;
}

.size {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: var(--neutral-10);
  border-radius: 2px;
}

.size.active {
  background-color: var(--positive);
}

.size+.size {
  margin-left: 4px;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  color: var(--light-brown);
  font-size: 11px;
  line-height: 14px;
}

.size.active .label {
  color: var(--whiteish);
}

.input {
  padding: 5px 8px;
  height: 36px;
  width: 89px;
  box-sizing: border-box;

  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: none;
  margin-right: 2px;
}

.button {
  margin-left: 16px;

  border: none;
  background-color: var(--positive);
  color: var(--whiteish);
  padding: 5px 16px;
}

.button:hover {
  background-color: var(--positive);
}

.basicPatternElement {
  padding: 2px 0;
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 3%;
  background: inherit;
  transition: background 0.3s;
  min-width: 128px;
  white-space: nowrap;
}

.basicPatternElement:focus {
  outline: none;
}

.nonEditable {
  cursor: pointer;
}

.void br {
  display: none
}
