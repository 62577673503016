.commentWriter {
  background: white;
  z-index: 100;
  width: 220px;

  border: 1px solid var(--neutral-10);
  border-radius: 4px;
}

.positive {
  color: var(--positive);
}

.buttonContainer {
  width: 100%;
  display: flex;
}

.buttonContainer > div {
  padding: 12px 6px;
  cursor: pointer;
  width: 50%;
  border-right: 2px solid var(--neutral-10);
  text-align: center;
}

.commentField {
  width: 94%;
  font-size: 14px;
  line-height: 1.25;
  border-left: 0;
  border-right: 0;
  border-top: 2px solid var(--neutral-10);
  border-bottom: 2px solid var(--neutral-10);
  padding: 5px;
  border-radius: 2px;
  color: var(--dark-brown);
}
