.container {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
}

.inlineContainer {
  width: 16px;
  height: 16px;
  border: 1px black solid;
  border-radius: 2px;
  margin-right: 4px;
  display: inline-block;
}

.fillParent {
  width: 100%;
  height: 100%;
}
