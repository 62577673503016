.container {
  box-sizing: border-box;
  overflow-x: visible;
}

.colors {
  overflow-x: visible;
}

/* YARN */
.yarnTitle {
  display: flex;
  flex-flow: column nowrap;
  margin: 16px 0;
}

.yarnName {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--small-font-family);
  color: var(--dark-brown);
  margin: 0 16px 4px 16px;
}

.titleHr {
  margin: 0;
  border-top: 1px solid var(--dark-brown);
}

.yarnInfo {
  color: var(--light-brown);
  font-size: 12px;
  line-height: 16px;
  font-family: var(--small-font-family);
  margin-top: 2px;
}
