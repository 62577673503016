.needles {
  margin-top: 16px;
}

.needle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.needle + .needle {
  margin-top: 8px;
}

.span {
  margin-left: 8px;
  font-family: var(--text-font-family);
  color: var(--dark-brown);
}

.span + .span {
  margin-left: 0.5ch;
}

.input {
  margin-left: 8px;
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
  padding: 3px 4px;
  flex-shrink: 1;
  width: 48px;
  font-family: var(--text-font-family);
  color: var(--darkest-brown);
  font-size: 14px;
  line-height: 20px;
}

.spacer {
  margin: 16px 0 8px 0;
}

.new {
  color: var(--contrast);
  font-size: 14px;
  line-height: 20px;
}

.deleteNeedle {
  display: flex;
  align-items: center;
  padding: 4px;
  margin-left: 8px;
  border: 2px solid var(--neutral-10);
  border-radius: 2px;
  cursor: pointer;
}

.deleteNeedle:hover {
  background-color: var(--neutral-10);
}

.gaugeNeedleContainer {
  padding: 12px 0;
  margin-bottom: 12px;
  border-bottom: 2px solid var(--neutral-10);
}

.gaugeNeedleContainer > p {
  font-size: 10px;
  color: var(--brown);
  margin-left: 24px;
}
