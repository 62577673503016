:root {
  --text-font-family: "forma-djr-text", sans-serif;
}

.hoverLarge {
  background-color: white !important;
}

.hoverLarge:not(:has(.hoverSmall:hover)):hover {
  background-color: var(--neutral-10) !important;
}

.gridButton {
  height: 150px;
  min-width: 120px;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
}

.addButton {
  border: 1px solid var(--positive);
  border-radius: 50px;
  z-index: 10;
  height: 15px;
  width: 15px;

  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
}

.gridCell {
  box-sizing:border-box;
  border-width: 0.5px;
  height: 5px;
  min-width: 5px;
  max-width: 5px;
}

.gridButton,
.box,
.gridCell,
.bordered {
border-color: #f0f0f0;
border-style: solid;
}

.gridButton,
.box {
border-radius: 5px;
border-width: 2px;
}

/* Darker background on mouse-over */
.gridButton:hover {
background-color: #bdd1b9 !important;
border-color: #bdd1b9;
}

.gridButton:focus-visible {
  outline-color: gray;
  outline-style: dotted;
}

.hoverBrown:hover {
  background-color: var(--lightest-brown);
  border-color: var(--lightest-brown);
}

.hoverBrownBordered:hover {
  background-color: var(--lightest-brown);
  border-color: var(--light-brown);
}


.noBackground {
  background-color: transparent;
  border: none;
}

.showScrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 0px;
}

.showScrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.showOnHoverComp {
  display: none;
}
  
.showOnHoverMask:hover + .showOnHoverComp {
  display: block;
}

.small {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 3px;
  align-self: center;
  border-width: 2px;
}

hr {
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

input:focus {
  outline-color: #bdd1b9;
}

.buttonContainer {
  display: flex;
}

.buttonContainer :not(:first-child) {
  margin-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.buttonContainer :not(:last-child) {
  margin-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.buttonContainerVertical {
  display: flex;
  flex-direction: column;
}

.buttonContainerVertical :not(:first-child) {
  margin-top: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.buttonContainerVertical :not(:last-child) {
  margin-bottom: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

body {
  font-weight: 400;
  font-family: var(--text-font-family), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes marching-ants {
  0% {
    background-position: 0 0, 10px 100%, 0 10px, 100% 0;
  }

  100% {
    background-position: 10px 0, 0 100%, 0 0, 100% 10px;
  }
}

.animated-border {
  background-image: linear-gradient(90deg, black 50%, transparent 50%),
    linear-gradient(90deg, black 50%, transparent 50%),
    linear-gradient(0, black 50%, transparent 50%),
    linear-gradient(0, black 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
  animation: marching-ants 400ms infinite linear;
}

.mobileOnly {
  display: flex;
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Black background with opacity */
  z-index: 2;
  font-size: 32px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
