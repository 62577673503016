.buttonContainer {
  display: flex;
  align-items: center;
}

.buttonContainer button {
  border-radius: 0;
  border: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 3%;

  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid var(--neutral-10);
  border-bottom: 1px solid var(--neutral-10);

  color: var(--neutral-40);
}

.buttonContainer button + button {
  border-left: none;
}

.buttonContainer button:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px solid var(--neutral-10);
}

.buttonContainer button:last-of-type {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px solid var(--neutral-10);
}

.buttonContainer button:hover {
  color: var(--neutral-40);
  border-radius: 2px;
  border: 1px solid var(--neutral-40);
  background-color: initial;
}

.buttonContainer button.active {
  color: var(--darkest-brown);
  border-radius: 2px;
  border: 1px solid var(--darkest-brown);
}

.buttonContainer button.active:hover {
  cursor: default;
  color: var(--darkest-brown);
  border: 1px solid var(--darkest-brown);
}

.buttonContainer input {
  margin-left: 8px;
  height: 32px;
  width: 48px;
  padding: 0 5px;
  border-width: 1px;
  box-sizing: border-box;

  color: var(--darkest-brown);
  font-size: 14px;
  line-height: 20px;
  text-align: end;
}

.buttonContainer input.active {
  border: 1px solid var(--darkest-brown);
}
