.headerSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  gap: 10%;
}

.headerSubSection {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 500px;
}


.headerAnnotation {
  color: var(--light-brown);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.headerTitle {
  font-family: "Forma DJR Banner";
  font-size: 38px;
  line-height: 79px;
  text-align: center;
  letter-spacing: -0.005em;
  margin-bottom: 1%;
  margin-block: auto;
  color: #000000;
}

.headerDescription {
  max-width: 724.62px;
  letter-spacing: -0.005em;
}

@media (max-width: 700px) {
  .headerDescription {
    font-size: 20px;
  }
}

.ctaButton {
  background-size: cover;
  background-image: url("assets/yarn_brown.png");
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 36px;
  padding-left: 7%;
  padding-right: 7%;
  margin-top: 3%;
  margin-bottom: 3%;
  max-width: fit-content;
}

@media (max-width: 700px) {
  .ctaButton {

    padding-top: 4%;
    padding-bottom: 4%;
    border-radius: 36px;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 5%;
    margin-bottom: 5%;

    font-size: 20px;
  }
}