.wrapper {
  margin: 0 16px;
}

.content {
  width: fit-content;
  margin: 0 auto;
}

.mainWrapper {
  width: calc(50vw - 16px);
  margin: 105px auto 300px auto;
}

.metaWrapper {
  display: flex;
  margin: 0 auto;
}

.mainWrapper a {
  color: var(--contrast);
  text-decoration: none;
}

.smallHr {
  border: 1px solid var(--neutral-10);
}

.hr {
  border: 1px solid var(--neutral-10);
  margin: 100px 0;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .metaWrapper {
    display: flex;
    flex-direction: column;
  }

  .mainWrapper {
    width: calc(100vw - 32px);
    margin: 0 16px;
  }
  .wrapper {
    margin: 0;
  }
}

@media print {
  
  .mainWrapper {
    width: 80%;
  }
  .metaWrapper {
    display: flex;
    flex-direction: column;
  }
}
