.buttonContainer {
  display: flex;
  padding: 17px 16px;
  align-items: center;
  border-top: 2px dashed var(--neutral-10);
  border-bottom: 2px dashed var(--neutral-10);

  width: 100%;
  box-sizing: border-box;
}

.buttonContainer button {
  border-radius: 0;
  background: none;
  padding: 9px 8px 7px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  box-sizing: border-box;

  font-size: 11px;
  line-height: 14px;
  font-family: var(--small-font-family);
  border: none;
  border-top: 1px solid var(--neutral-10);
  border-bottom: 1px solid var(--neutral-10);
}

.buttonContainer button svg {
  margin-top: -1px;
  margin-bottom: 1px;
}

.buttonContainer button + button {
  border-left: none;
}

.buttonContainer button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid var(--neutral-10);
}

.buttonContainer button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid var(--neutral-10);
}

.buttonContainer .active svg {
  margin-right: 8px;
}

.buttonContainer button.active {
  color: var(--darkest-brown);
  border: 1px solid var(--darkest-brown);
  border-radius: 4px;
}

.buttonContainer button:hover:not(.active) {
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
}

.buttonContainer .active:hover {
  cursor: default;
}
