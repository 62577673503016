.paragraphBreak {
  height: 16px;
  margin-left: -43px;
  /* width: 10vw; */
  display: flex;
  align-items: center;
}

.paragraphButton {
  height: 32px;
}

.paragraphLine {
  width: 100%;
  border-color: var(--positive);
}
