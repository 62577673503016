.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  padding: 0 10vw 0 0;
  background-color: var(--whiteish);
  border-bottom: 2px solid var(--neutral-10);
}

.lockContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 16px;
  width: 10vw;
  align-items: flex-end;
  box-sizing: border-box;
}

.lock {
  border: none;
  background: none;
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 15px;
}

.lock svg {
  height: 15px;
  flex-shrink: 0;
}

.lock:hover {
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
  background: none;
}

.label {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-left: 2px solid var(--neutral-10);
}

.input {
  width: calc(100% - 32px);
  padding: 8px 9px 6px 9px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  outline: none;
  background: none;

  font-size: 14px;
  line-height: 20px;
  color: var(--darkest-brown);
  font-weight: 700;
  letter-spacing: 3%;
  font-family: var(--small-font-family);

  margin: 16px;
}

.input:hover {
  border: 1px solid var(--neutral-40);
  padding: 7px 8px 5px 8px;
}

.input:focus {
  border: 1px solid var(--darkest-brown);
  padding: 7px 8px 5px 8px;
}

.labelControl {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 246px;
  box-sizing: border-box;
}

.buttonContainer {
  display: flex;
  padding: 16px 0;
  align-items: center;
  border-top: 2px dashed var(--neutral-10);
  border-bottom: 2px dashed var(--neutral-10);
}

.buttonContainer button {
  border-radius: 0;
}

.buttonContainer button + button {
  border-left: none;
}

.buttonContainer button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.buttonContainer button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.active {
  background-color: var(--neutral-10);
  color: var(--light-brown);
}

.active:hover {
  background-color: var(--neutral-10);
  cursor: default;
}

.edgeLabels {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  border-left: 2px solid var(--darkest-brown);
}

.edgeLabels div {
  display: flex;
  align-items: center;
  height: 66px;
  padding: 23px 16px 21px 16px;
  box-sizing: border-box;

  font-size: 14px;
  line-height: 20px;
}

.edgeLabels div + div {
  border-top: 2px solid var(--neutral-10);
  height: 68px;
}

.rowCells {
  display: flex;
  flex-flow: row nowrap;
}

.measureType {
  width: 116px;
  box-sizing: border-box;
  border-left: 2px solid var(--darkest-brown);
  border-right: 2px solid var(--neutral-10);
}
