.container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: center;
}

.imageContainer {
  display: flex;
  margin-top: 16px;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 50%;
}

.imageContainer > button {
  display: none;
}

.imageContainer:hover > button {
  display: block;
}

.altText {
  margin-left: 8px;
  margin-right: 8px;
}

.removeButton {
  position: absolute;
  left: calc(50% - 34px);
}

.image {
  width: 80%;
}
