.listContainer {
  display: flex;
  flex-flow: column nowrap;
}

.container > * + * {
  margin-top: 2em;
}

.spacer {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--neutral-10);
  margin: 0;
}

.new {
  color: var(--contrast);
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}
