.wrapper {
  padding: 0px 24px 24px 24px;
}

.confirmationButtons {
  display: flex;
}

.confirmationButtons > button {
  margin: 0 4px;
  flex-grow: 1;
}

.message {
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
}

.yesButton {
  width: 6rem;
  background-color: yellow;
}
.yesButton:hover {
  background-color: red;
}
.noButton {
  background-color: lightgrey;
}
.noButton:hover {
  background-color: grey;
}

.form {
  margin: auto;
  padding-top: 30px;
  width: 330px;
}

.input {
  margin-bottom: 30px;
  padding: 5px 15px;
  width: 296px;
}

.template {
  margin-bottom: 32px;
}

.template > label {
  margin-left: 4px;
  font-size: 12px;
}
