.container {
  display: flex;
  justify-content: flex-end;
}

.container > div {
  padding: 4px 12px;
  border-radius: 4px;
  flex-shrink: 1;
  margin-right: 8px;
  display: inline-block;
}

.positive {
  background-color: rgba(61, 128, 54, 0.1);
  color: var(--positive) !important;
}

.contrast {
  background-color: rgba(204, 78, 0, 0.1);
  color: var(--contrast) !important;
}
