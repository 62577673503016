.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -2px;
  position: relative;
  z-index: 2;
  padding: 0 10vw;
}

.labelContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.label {
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: var(--light-brown);
}

.input {
  font-size: 38px;
  line-height: 1;
  color: var(--darkest-brown);

  padding-bottom: 8px;
  width: 432px;

  /* If .input is empty, displace it vertically by size of .label */
  margin-top: 14px;

  border: none;
  border-bottom: 2px solid var(--neutral-10);
  border-radius: 0;

  outline: none;
}

.label + .input {
  /* Input is not empty; label present, remove placeholder displacement*/
  margin-top: 0;
}

.input:hover {
  border-bottom-color: var(--neutral-40);
}

.input:focus {
  color: var(--positive);
  border-bottom-color: var(--positive);
}

.markContainer {
  height: 59px;
  display: flex;
  align-items: center;
}

.markLabel {
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
  letter-spacing: 3%;
}

.deleteTable {
  color: var(--negative);
  border-color: var(--negative);
  position: absolute;
  left: calc(10vw - 57px - 16px);
}

.deleteTable:hover {
  color: white;
  background-color: var(--negative);
}
