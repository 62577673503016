.container {
  border-bottom: 1px solid var(--neutral-10);
}

.odd {
  background: var(--whiteish);
}

.odd:hover {
  background: var(--whiteish) !important;
}

.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-10);
}

.answer {
  margin: 6px 0;
}

.subtleButton {
  border: 0;
  text-align: left;
  width: 100%;
  border-radius: 0;
}

.subtleButton:hover {
  background: white;
}

.active {
  padding: 24px;
}

.inactive {
  padding: 24px;
}

.comment {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--neutral-10);
}

.buttonRow {
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
}

.writeAnswer {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 2px solid var(--neutral-10);
}

.input {
  border: 0;
  flex-grow: 4;
}

.button {
  border: 0;
  padding: 2px;
}

.button:hover {
  background: white;
}

.answers {
  padding-top: 4px;
}

.answerRow {
  display: flex;
  justify-content: space-between;
}

.textarea {
  margin-right: 8px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--neutral-10);
}
