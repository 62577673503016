.hoverable {
  width: 100%;
  background: white;
  padding: 12px 0;
}

.positive {
  color: var(--positive);
}

.buttonRow {
  border: 2px solid var(--neutral-10);
  border-radius: 5px;
  border-right: none;
}

.buttonRow > button {
  border: none;
  border-radius: 0;
  border-right: 2px solid var(--neutral-10);
}

.hoverable:hover {
  background: var(--whiteish);
}

.closeButton {
  border: 0;
}

.color {
  display: flex;
}

.tableRow {
  display: flex;
}

.tableHead {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.sizeLabelRow {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.table {
  padding: 16px 32px;
  width: 800px;
}

.tableLabel {
  font-weight: 600;
}

.tableRowLabel {
  width: 40%;

  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.rowNumbers {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  z-index: 101;
  background: white;
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
  min-width: 360px;
}

.inputField {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.imageRow {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
}

.link {
  color: var(--contrast) !important;
}

.basicPatternElement {
  padding: 2px 0;
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 3%;
  background: inherit;
  transition: background 0.3s;
  min-width: 128px;
}

.basicPatternElement:focus {
  outline: none;
}

.nonEditable {
  cursor: pointer;
}

.void br {
  display: none
}
