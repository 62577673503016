.modalContent {
  width: 320px;
}

.intervalSection {
  margin-top: 12px;
}

.intervalContainer {
  display: flex;
  justify-content: space-between;
}

.commentInput {
  border: 0;
  border-bottom: 1px solid var(--neutral-40);
  outline: none;
  max-width: 98%;
  min-width: 98%;
}

.commentInput:focus {
  border-bottom: 1px solid var(--positive);
}

.intervalInput {
  border: none;
  text-align: center;
  width: 22px;
  color: var(--contrast);
}

.intervalInput::-webkit-inner-spin-button,
.intervalInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.middle {
  font-size: 16px;
  margin: 2px 8px;
  color: var(--contrast);
}

.interval {
  display: flex;
  width: 120px;
  justify-content: space-between;
}

.colGroup {
  display: flex;
}

.col {
  height: 20px;
  border-left: 2px solid var(--neutral-40);
  cursor: pointer;
}

.colActive {
  border-left: 2px solid var(--contrast);
}

.rowGroup {
  display: flex;
  flex-direction: column;
}

.row {
  border-top: 2px solid var(--neutral-40);
}

.rowActive {
  border-top: 2px solid var(--contrast);
}

.right {
}

.label {
  font-size: 12px;
}

.sizes {
  margin-top: 12px;
}

.sizeButtons > * + * {
  margin-left: 2px;
}

.activeSize {
  border: 1px solid var(--positive);
  color: var(--positive);
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--neutral-10);
  margin-top: 12px;
}

.buttonGroup > button {
  margin-top: 12px;
}
