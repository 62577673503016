.container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  box-sizing: border-box;
}

.controls {
  margin-top: 8px;
  display: flex;
  flex-flow: column nowrap;
}

.controls > div + div {
  margin-top: 8px;
}

.search {
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
  font-size: 12px;
  line-height: 22px;
  padding: 4px 8px;
  font-family: var(--text-font-family);
  margin-bottom: 8px;
}

.colorListContainer {
  flex-grow: 1;
  margin: 0 -16px;
  min-height: 600px;
}
