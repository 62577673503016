.colorContainer {
  padding: 6px;
  border: 1px solid var(--neutral-10);
  border-top: 0;
}

.variantNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  border-bottom: 1px solid var(--neutral-10);
  min-width: 34px;
}

.variantName {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 4px 12px 4px 8px;
}
