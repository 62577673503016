.onboardingSection {
  padding-top: 2%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: bottom;
  background-color: #ffffff;
  height: fit-content;
  justify-content: center;
}



@media (max-width: 700px) {
  .onboardingSection {
    margin-top: 5%;
    flex-direction: column;
    padding:4%;
  }
}

.onboardingTag{
  color: #8F6D57;
   font-size: 20px
}

@media (max-width: 700px) {
  .onboardingTag {
    font-size: 26px
  }
}

.onboardingCardContainer {
  margin: 1%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.onboardingCard {
  width: 350px;
  background: var(--whiteish);
  border-radius: 5px;
  padding-top: 2%;
  padding-bottom: 2%;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 700px) {
  .onboardingCard {
    margin-bottom: 5%;
  }
}


.flaggedCard {
  border: 1px solid #f27900;
  position: relative;
}

.descriptionField {
  width: 100%;
  align-self: left;
}

.description {
  font-size: 16px;
  font-family: "forma-djr-display";
  margin-left: 10px;
}
@media (max-width: 700px) {
  .description {
    font-size: 20px;

  }
}

.descriptionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 3%;
}

.recommendedTag {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: var(--contrast);
  border-radius: 4px;
  color: #ffffff;
  padding-top: 4px;
  align-items: center;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 16px;
}
