.listBoxOptions {
  background: white;
  list-style-type: none;
  padding-left: 0;
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.listBoxButton {
  display: flex;
  align-items: center;
  height: 30px;
}

.listBoxOption {
  padding: 6px 4px;
  display: flex;
}

.active {
  background: var(--neutral-10);
  color: var(--positive);
}

.color {
  width: 9px;
  height: 9px;
  border-radius: 2px;
  margin: 2px;
}
