:root {
  --grid-cell: #ccc9c7;
}

.variant {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px 24px;
  margin: 0 -24px;
  cursor: pointer;
}

.variant:hover,
.variant.active {
  background-color: var(--white-ish);
}

.editColorButton {
  padding: 2px;
  border-radius: 0;
  border: 0;
}

.editColorButton > div {
  width: 18px;
  height: 18px;
}

.colorContainer {
  width: 24px;
  height: 24px;
  border: 1px solid var(--grid-cell);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.colorContainer + .colorContainer {
  border-left: none;
}

.colorContainer > div {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.colorContainer:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.colorContainer:first-child > div {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.colorContainer:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.colorContainer:last-child > div {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.text {
  flex-grow: 1;
  flex-shrink: 1;
  font-family: var(--text-font-family);
}

.name {
  color: var(--light-brown);
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.colorString {
  color: var(--dark-brown);
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.colors {
  display: flex;
  flex-flow: row nowrap;
  margin-right: 8px;
}

.container {
  margin: 84px 16px 16px 16px;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.title {
  font-size: 20px;
  line-height: 22px;
  color: var(--darkest-brown);
  font-weight: 600;
  font-family: var(--header-font-family);
  background: none;
  border: none;
  width: 100%;
  margin: 0 -16px;
  margin-top: 45px;
  padding: 8px 16px 4px 16px;
}

.title:hover {
  background-color: var(--neutral-10);
}

.spacer {
  margin: 0 -16px;
}

.between {
  margin-top: 32px;
}

.preview {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.colorLabel {
  font-family: var(--text-font-family);
  color: var(--darkest-brown);
  margin-left: 8px;

  display: flex;
  flex-flow: row nowrap;
}

.colorNameInput {
  background: initial;
  border: none;
  outline: none;
  color: var(--darkest-brown);
  font-size: 16px;
  line-height: 1.3em;
  letter-spacing: 0.03em;
  margin: -1px -2px;
}

.colorNameInput:focus {
  background-color: var(--white-ish);
}

.colorHex {
  color: var(--darkest-brown);
}

.rowContainer {
  padding: 16px;
  margin: 0 -16px;
  border-bottom: 1px solid var(--neutral-10);
}

.rowContainer:hover {
  background-color: var(--neutral-10);
}

.pusher {
  flex-grow: 1;
}

.delete {
  border-color: var(--white-ish);
  box-shadow: 0 0 0 2px var(--positive);
  border-radius: 0;
  background-color: var(--positive);
  width: 16px;
  height: 16px;
  overflow: hidden;
  padding: 0;
  flex-shrink: 0;
}

.delete:hover {
  background-color: var(--positive);
}

.new {
  background-color: var(--white-ish);
  color: var(--positive);
  margin-top: 16px;
}

.colorList {
  display: flex;
  flex-flow: column nowrap;
}

.sectionTitle {
  font-family: var(--header-font-family);
  color: var(--dark-brown);
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 4px;
}

.deleteSection {
  padding: 8px;
  border-radius: 4px;
  border: 2px solid var(--neutral-10);
  box-sizing: border-box;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  font-family: var(--text-font-family);
  color: var(--contrast);
}

.deleteSection:disabled {
  color: var(--neutral-40);
  background-color: var(--neutral-10);
}

.deleteSection:hover {
  background-color: var(--neutral-10);
}

imageButtonContainer {
  display: flex;
  flex-wrap: wrap;
}

.imageButton {
  border: 0;
  padding: 0;
  width: 50%;
  background: 0;
  margin: 16px 0;
}

.imageButton:hover {
  background: 0;
}

.image {
  width: 80%;
}

.disabledText {
  margin-top: 8px;
  margin-left: 2px;
  color: var(--light-brown);
  font-size: 12px;
}
