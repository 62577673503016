@media print {
  .btnGroupContainer {
    opacity: 0;
  }
  .title {
    break-before: always;
  }
}

.diagramCell {
  border: 1px solid var(--whiteish);
  border-left: 0;
  border-top: 0;
  box-sizing: border-box;
}

.diagram {
  margin-left: calc(-25vw + 16px);
  width: calc(100vw - 48px);
}

.fitted {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: visible;
}

.fullscreen {
  overflow-x: scroll;
  overflow-y: visible;
}

.diagramCell > div {
  width: 100%;
  height: 100%;
}

.rightPatternItem {
  border: 2px solid var(--dark-brown);
  border-left: 0;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
}

.colComment {
  /* grid-column: diagram-left / diagram-right;
  /* grid-row: legend-bottom / col-comment; */
  display: grid;
  flex-flow: row-reverse nowrap;
  margin-left: 2px;
}

.bottomPattern {
  grid-row: col-comment / pattern-bottom;
  grid-column: diagram-left / diagram-right;
  display: grid;
  margin-left: 2px;
}

.bottomPatternItem {
  border: 2px solid var(--dark-brown);
  border-radius: 12px;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  width: 100%;
  text-align: left;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dividerLineTop {
  border-bottom: 1px solid black;
  border-radius: 0px;
}

.dividerLineBot {
  border-top: 1px solid black;
  border-radius: 0px;
}

.btnGroupContainer {
  display: flex;
  justify-content: space-between;
}

.btnGroup > button {
  margin-right: 8px;
}

.legend {
  width: 220px;
}

.legendItem {
  display: flex;
  border-bottom: 1px solid var(--whiteish);
  padding: 8px;
  align-items: center;
}

.legendValue {
  border-radius: 3px;
  margin-right: 8px;
}

.legendColor {
  height: 22px;
  width: 22px;
}

.legendSymbol {
  height: 22px;
  width: 22px;
  border: 2px solid var(--whiteish);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.legendContainer {
  /* flex-grow: 1; */
  margin-right: 120px;
}

.dynamicLegendSymbol {
  border: 2px solid var(--whiteish);
  margin-right: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.dynamicSymbol {
  display: grid;
  line-height: 1;
}