:root {
  --darkest-brown: #33251a;
  --neutral-10: #eeecea;
  --white-ish: #faf6f2;
}

.drawerContainer,
.drawerBackground {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9001;
}

.toggleDrawer {
  position: absolute;
  z-index: 9010;
  top: 24px;
  color: var(--dark-brown);
  padding: 8px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid var(--neutral-10);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.openDrawer {
  left: 24px;
  padding: 8px 16px;
}

.closeDrawer {
  right: calc(100% - 500px + 24px);
}

.closeSubdrawer {
  right: calc(100% - 500px - 350px + 24px);
}
.crossButton {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.drawerBackground {
  background: rgba(51, 37, 26, 0.1);
  /* Background blur */
  backdrop-filter: blur(3px);
}

.drawer {
  position: absolute;
  left: 0;
  top: 0;
  width: 50vw;
  height: 100%;
  z-index: 9005;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 20px var(--neutral-10);
  border-radius: 4px;
}

.subdrawer {
  position: absolute;
  left: 50vw;
  top: 0;
  width: 350px;
  height: 100%;
  z-index: 9007;
  background-color: var(--white-ish);
  overflow-y: scroll;
}

.closeSubdrawerButton {
  text-align: right;
  width: 100%;
}

.drawerSection {
  padding: 24px 18px 24px 21px;
  /* padding: 24px; */
}

.drawerTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 24px;
  width: 100% - 14px;
  height: 1;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--neutral-40);
  opacity: 1; /* Firefox */
}
.drawerTitle {
  width: 100%;
  color: var(--neutral-40);
  font-size: 38px;
  font-weight: 400;
  line-height: 1;
  border: none;
  border-spacing: 0.3rem;
  border-bottom: 2px solid var(--neutral-40);
}
.drawerTitle:focus {
  color: var(--positive);
  outline: none;
  border-spacing: 0.3rem;
  border-bottom: 2px solid var(--positive);
}

.drawerDescription {
  font-family: var(--header-font-family);
  color: var(--neutral-40);
  height: 100%;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  max-width: 100%;
  min-height: 2em;
  height: fit-content;
  border: none;
}

.drawerDescription:focus {
  color: var(--positive);
  outline: none;
  border-spacing: 0.3rem;
  border-bottom: 2px solid var(--positive);
}
.titleSpacer {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid var(--neutral-10);
}

.hr {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid var(--neutral-10);
}

.subtitle {
  font-family: var(--header-font-family);
  color: var(--darkest-brown);
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  border-bottom: 2px solid var(--neutral-10);
  margin: 0 -24px;
  padding: 0 24px;
}
.smallSubtitle {
  font-family: var(--header-font-family);
  color: var(--neutral-40);
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  border-bottom: 0px solid var(--neutral-10);
  margin: 0 -24px;
  padding: 0 24px;
  width: 100%;
}

.hidden {
  display: none;
}
