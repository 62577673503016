.symbolRow {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.overflowX {
  overflow-x: auto;
  max-width: 310px;
}

.symbolRow:hover {
  background: #faf6f2;
}

.symbolContainer {
  border-right: 1px solid var(--neutral-10);
  border-bottom: 1px solid var(--neutral-10);
  min-width: 36px;
  height: 100%;
}

.symbolBox {
  display: flex;
  height: calc(100% - 2px);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
}

.symbolLabel {
  margin-left: 12px;
}

.center {
  display: block;
  margin: auto;
  margin-top: 6px;
  width: 50%;
}

.fl {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.add {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 0;
}
