.modal {
  width: 432px;
  padding: 40px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.symbolGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.symbolContainer {
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
}

.symbolContainer:disabled {
  background: var(--neutral-10);
  cursor: default;
}

.dynamicSymbolContainer {
  margin: 4px;
}

.dynamicSymbolContainer:disabled {
  background: var(--neutral-10);
  cursor: default;
}

.active {
  display: flex;
}

.activeSymbol {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 0 8px;
}

.input {
  height: 30px;
  margin-left: 8px;
  flex-grow: 1;
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.hr {
  border: 1px solid var(--neutral-10);
}
