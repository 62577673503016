.processSection {
  background-color: var(--whiteish);
  justify-content: center;
  display: flex;
}

@media (max-width: 920px) {
  .processSection {
    padding: 30px;
  }
}

.processImageContainer {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
}

.processImageCard {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 2%;
  padding: 50px;
}

@media (max-width: 920px) {
  .processImageCard {
    padding: 0;
  }
}


.processImageDescriptionContainer {
  display: flex;
  padding-top: 30px;
}

.processImageDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 320px;
}


.processImageTitle {
  margin-block: auto;
  margin-bottom: 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.processImage {
  max-height: 276px;
  max-width: 220px;
}

.processIcon {
  background-color: var(--mainBrown);
  width: 30px;
  height: 30px;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  line-height: 30px;
  margin-right: 15px;
}