.node {
  opacity: 1;
}
.stitch {
  object-fit: cover;
}
.node:hover {
  opacity: 0.5;
}
.svgContainer {
  display: inline-block;
  position: absolute;
  max-width: 100%;
  width: 100vw;
  height: 80vw;
  /* padding-bottom: 100%; */
  vertical-align: top;
  overflow: auto;
}
#viz {
  width: 100%;
  height: 100%;

  overflow: auto;
}
#wrapper {
  /* overflow-y: scroll; */
  overflow-x: scroll;
}
#zoomable {
  /* position: absolute; */
}

.spinnerContainer {
  width: 100px;
  padding: 20px;
}

@keyframes spinner-pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  to {
    transform: scale(1);
  }
}

.spinner {
  width: 100%;
  height: 100%;
  animation: spinner-pulse 4s infinite;
}
