.big {
    min-width: 550px !important;
}

.biggest {
    min-width: 1100px !important;
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    transform: translate(-50%, -50%) !important;
}