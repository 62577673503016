.buttonRow {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 24px;
}

.buttonRow > * + * {
  margin-top: 24px;
}

.warningButton {
  color: var(--contrast);
}

.yesButton {
  color: var(--positive);
}

.sectionTitle {
  font-size: 20px;
  color: var(--header-color);
  font-weight: 400;
  text-transform: uppercase;

  margin-top: 50px;
}

.spacer {
  margin: 0;
  margin-top: 100px;
}
