.hidden {
  display: none;
}

.drawerContainer,
.drawerBackground {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9001;
}

.drawer {
  position: absolute;
  left: 0;
  top: 0;
  width: 25vw;
  height: 100%;
  z-index: 9005;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 20px var(--neutral-10);
  border-radius: 4px;
}

.drawerBackground {
  background: rgba(51, 37, 26, 0.1);
  /* Background blur */
  backdrop-filter: blur(3px);
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 24px 12px;
  border-bottom: 1px solid var(--neutral-30);
}

/* Sub-sections */

.menuButton {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--neutral-30);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

.borderBottom {
  border-bottom: 1px solid var(--neutral-30);
}

.org {
  width: 100%;
  border: none;
  min-height: 36px;
  text-align: left;
  padding-left: 24px;
}
