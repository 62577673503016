.wrapper {
  max-width: 45vw;
}

.sizeSelector {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  margin: 36px 0;
}

.recipeSection {
  padding-left: 18px;
  padding-right: 18px;
}

.recipeSection {
  margin-bottom: 0;
}

.metaContainer {
  display: flex;
  flex-flow: wrap;
  max-width: 60ch;
}

.metaContainer > div {
  width: 30ch;
  white-space: pre-wrap;
}

.variantContainer {
  margin-left: -16px;
  width: calc(100% + 32px);
}

.positive {
  color: var(--positive);
}

.meta {
  margin-bottom: 24px;
}

.label {
  color: var(--light-brown);
  font-weight: 400;
  font-size: 12px;
}

.value {
  color: var(--dark-brown);
  font-weight: 400;
  font-size: 16px;
}

.capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    max-width: 100vw;
    margin: 40px 16px 0 16px;
  }
}
