.drawerContainer {
  background-color: var(--white-ish);
  margin: 84px 16px 16px 16px;
}

.sectionTitle {
  font-family: var(--header-font-family);
  color: var(--darkest-brown);
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  border-bottom: 2px solid var(--neutral-10);
  padding: 0 24px;
}

.uploadContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 24px;
}

.uploadButton {
  background-color: var(--white-ish);
  padding-top: 5px;
}
/* should h2 be .heading instead? */
.h2 {
  font-size: 16px;
  font-family: var(--small-font-family);
}

.sectionRuler {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  margin-left: 5px;
  height: 4px;
  background-color: var(--neutral-10);
}

.imageContainer {
  position: relative;
  display: inline-block;
}

.imageContainer:hover .deleteButton {
  display: block;
}

.deleteButton {
  display: none;
  position: absolute;
  width: 60px;
  top: 50%;
  margin-top: -15.5px;
  left: 50%;
  margin-left: -30px;
  text-align: center;
}
