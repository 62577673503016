.container {
  display: flex;
  align-items: center;
}

.profilePicture {
  width: 32px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid var(--neutral-20);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: var(--darkest-brown);
}
