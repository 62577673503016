.tagsInputContainer {
 padding-top: 10px;
 padding-bottom: 20px;
  border-radius: 3px;
  width: min(80vw, 700px);
  margin-top: 0.2em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}



.tagItem {
    padding: 5px 10px 3px 10px;
    border: 1px solid var(--neutral-10);
    font-family: var(--small-font-family);
    background-color: var(--whiteish);
    border-radius: 4px;
    background-color: #fff;
    color: var(--dark-brown);
    font-size: inherit;
    cursor: pointer;
}

.tagItem .text {
  font-size: 12px;
}

.tagItem:hover {
    background-color: var(--neutral-10);
  }

.chosenTagItem {
    padding: 5px 10px 3px 10px;
    border: 1px solid var(--neutral-10);
    font-family: var(--small-font-family);
    background-color: var(--whiteish);
    border-radius: 4px;
    background-color: #fff;
    color: var(--dark-brown);
    font-size: inherit;
    cursor: pointer;
    
    border-color: var(--positive);
}

.chosenTagItem .text {
  
  font-size: 12px;
  color: var(--positive);
}

.chosenTagItem:hover {
    background-color: var(--neutral-10);
  }

.chosenTagItem .close {
  height: 20px;
  width: 10px;
  color: var(--positive);
  display: inline-flex;
  justify-content: right;
  align-items: center;
  margin-left: 0.1em;
  font-size: 12px;
  cursor: pointer;
}

.tagsInput {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-size: 12px;
}
