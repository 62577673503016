.mobileOnly {
  display: flex;
  position: fixed;
  flex-direction: column;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,
      255,
      255,
      0.6);
  /* Black background with opacity */
  z-index: 2;
  font-size: 32px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.mobileOnly p {
  font-size: 32px;
  font-weight: 500;
  margin: 40px;
}

.mobileOnly a {
  text-decoration: underline;
}