.modalContent {
  display: flex;
  padding: 0 8px;
}

.inputContent {
  padding-left: 24px;
}

.bottomContent {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}


.colorOptionsContainer {
  max-width: 460px;
}

.colorOptionsContainer > button {
  padding: 12px;
  margin: 4px;
  border: 0;
}
