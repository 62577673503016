@media print {
  .footer {
    opacity: 0;
  }
}

.footer {
  position: -webkit-fixed;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  /* height: 96px; */
  align-items: center;
}

.filled {
  background-color: white;
}

.wrapper {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
}

.publishModal {
  min-width: 500px;
  margin: 0 30px 30px 30px;
}

.h2 {
  font-size: 18px;
  line-height: 24px;
  /* font-weight: bold; */
}

.priceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceInput {
  display: inline-block;
  width: 40px;
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pricePositive {
  color: var(--positive);
}

.priceDetail {
  border-bottom: 1px solid var(--neutral-10);
  display: flex;
  justify-content: space-between;
  color: var(--light-brown);
  font-size: 14px;
  line-height: 20px;
}

.priceSum {
  display: flex;
  justify-content: space-between;
  color: var(--light-brown);
  font-size: 18px;
  line-height: 24px;
}

.previousPublishedSection {
  margin-top: 20px;
}

.commentSection {
  margin-top: 20px;
}

.publishSection {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.textArea {
  font-family: var(--header-font-family);
  color: var(--neutral-40);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  min-height: 2em;
  border: none;
  border-bottom: 2px solid var(--neutral-40);
}

.textArea:focus {
  color: var(--positive);
  outline: none;
  border-spacing: 0.3rem;
  border-bottom: 2px solid var(--positive);
}

.commentButton {
  position: relative;
}

.notification {
  color: white;
  background: var(--contrast);
  font-size: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  right: -5px;
  top: -5px;
}

.right > * {
  margin: 0 4px;
}
