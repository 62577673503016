.active {
  background-color: var(--dark-brown);
  color: var(--whiteish);
}

.active:hover {
  background-color: var(--light-brown);
}

.inactive:hover {
  background-color: var(--neutral-10);
}

.sizeSelector {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 18px;
  padding-right: 18px;
}

.buttonContainer > button {
  margin: 4px;
}
