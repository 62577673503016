.wrapper {
  padding: 0 40px 40px 40px;
}

.label {
  font-size: 12px;
  margin-left: 8px;
}

.firstOption {
  margin-right: 42px;
}

.hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid var(--neutral-10);
}

.exportOptionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}