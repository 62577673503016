.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  border-bottom: 2px solid var(--neutral-10);
  position: relative;
}

.lockContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 16px;
  width: 10vw;
  align-items: flex-end;
  box-sizing: border-box;
}

.lock {
  border: none;
  background: none;
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 15px;
}

.lock svg {
  height: 15px;
  flex-shrink: 0;
}

.lock:hover {
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
  background: none;
}

.label {
  width: 248px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-left: 2px solid var(--neutral-10);
}

.input {
  width: 100%;
  padding: 8px 9px 6px 9px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  outline: none;

  font-size: 14px;
  line-height: 20px;
  color: var(--darkest-brown);
  font-weight: 700;
  letter-spacing: 3%;
  font-family: var(--small-font-family);
}

.input:hover {
  border: 1px solid var(--neutral-40);
  padding: 7px 8px 5px 8px;
}

.input:focus {
  border: 1px solid var(--darkest-brown);
  padding: 7px 8px 5px 8px;
}

.cells {
  display: flex;
  flex-flow: row nowrap;
}

.unit {
  width: 116px;
  box-sizing: border-box;
  border-left: 2px solid var(--darkest-brown);
  border-right: 2px solid var(--neutral-10);
}

.pusher {
  flex-grow: 1;
  border-left: 2px solid var(--darkest-brown);
  padding: 16px;
}

.deleteContainer {
  width: 10vw;
}

.delete {
  margin: 16px;
  height: 32px;
  width: 32px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: none;
}

.delete:hover {
  border: 1px solid var(--negative);
  background: none;
}

.confirmDelete {
  border-color: var(--negative);
  color: var(--negative);
}

.confirmDelete:hover {
  background-color: var(--negative);
  color: white;
}

.modalButtonContainer {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
}

.modalButtonContainer button {
  padding: 16px 32px;
  width: 100px;
}

.modalButtonContainer button + button {
  margin-left: 32px;
}
