.row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 0 10vw;
  height: 64px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.label {
  border-left: 2px solid var(--neutral-10);
  padding: 16px 24px;
  width: 248px;
  box-sizing: border-box;

  color: var(--neutral-40);
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  display: flex;
  align-items: center;
}

.sizes {
  flex-grow: 1;

  border-left: 2px solid var(--neutral-10);
  border-right: 2px solid var(--neutral-10);
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.sizeCell {
  width: 114px;
  box-sizing: border-box;
  border-left: 2px solid var(--neutral-10);

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-40);
}

.unit {
  width: 112px;
  border-right: 2px solid var(--neutral-10);
}

.row:hover {
  border-top: 2px solid var(--positive);
  margin-top: -2px;
  height: 66px;
}

.newButton {
  position: absolute;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  padding: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 16px;
  left: calc(50% - 16px);
}

.newButton:hover {
  border-color: var(--positive);
  background: white;
}
