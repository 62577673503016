.container {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
}

.active {
  color: var(--positive);
  border-color: var(--positive);
}

.size {
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
  min-width: 30px;
  border-width: 1px;
}

.select {
  position: relative;
}

.listboxButton {
  width: 128px;
  display: flex;
  justify-content: space-between;
}

.options {
  background: white;
  list-style-type: none;
  padding-left: 0;
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
  position: absolute;
  width: 100%;
  z-index: 20;
}

.option {
  padding: 2px 4px;
  font-size: 12px;
}

.newSizeGroupInput {
  width: 76px;
  height: 28px;
  margin-right: 4px;
  border: none;
  font-size: 12px;
  margin-left: 4px;
}

.newSizeGroupContainer {
  border-top: 1px solid var(--neutral-10);
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
}

.newSizeGroupButton {
  border: none;
}
