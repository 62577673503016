.wdsBanner {
  margin-top: 10px;
  background-color: var(--lightGreen);
  height: 47px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #33251a;
}

@media (max-width: 700px) {
  .wdsBanner {
    padding-top: 10px;
    height: 70px;
    width: 100%;
    flex-direction: column;
  }
}

.statusSection {
  background-color: var(--light-brown);
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 10px;
}

.headerSection {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-image: url("../../Visualizing/LandingPage/yarn_background.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0% 100%;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

.headerSubSection {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 500px;
}

.goToWDSLink {
  color: #3d8036;
  margin: 12px;
  cursor: pointer;
}

@media (max-width: 700px) {
  .goToWDSLink {
    display: none;
  }
}

.navLinkContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media (max-width: 700px) {
  .navButton {
    display: none;
  }
}

.headerTitle {
  font-family: "Forma DJR Banner";
  font-size: 38px;
  text-align: center;
  letter-spacing: -0.005em;
  margin-bottom: 1%;
  color: #000000;
}

.headerDescription {
  max-width: 724.62px;
  text-align: center;
  letter-spacing: -0.005em;
  margin: 5px 25px;
}

@media (max-width: 700px) {
  .headerDescription {
    font-size: 20px;
  }
}

.loginBtn {
  max-width: 102px;
  background: var(--lightGreen);
  border-radius: 4px;
  border: none;
  color: var(--darkest-brown);
  margin: 12px;
  float: right;
}

.woolitIcon {
  margin: 12px;
  cursor: pointer;
  align-self: center;
}

.submitBtn {
  height: 42px;
  width: 30vw;
  box-sizing: border-box;
  max-width: 150px;
  margin-left: 10px;
  background: var(--lightGreen);
  border-radius: 8px;
  word-wrap: break-word;
  white-space: normal;
  font-size: medium;
  border: none;
  color: var(--darkest-brown);
  cursor: pointer;
}

.emailInput {
  width: 60vw;
  box-sizing: border-box;
  max-width: 400px;
  background: var(--whiteish);
  text-align: left;
  border-radius: 8px;
  padding-left: 5px;
  letter-spacing: -0.005em;
  border: none;
}

.emailInputBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.processSection {
  background-color: var(--whiteish);
  justify-content: center;
  display: flex;
}

@media (max-width: 920px) {
  .processSection {
    padding: 30px;
  }
}

.processImageContainer {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
}

.processImageCard {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 2%;
  padding: 50px;
}

@media (max-width: 920px) {
  .processImageCard {
    padding: 0;
  }
}

.processImageDescription {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-height: 30px;
  padding-top: 30px;
}

.processImage {
  max-height: 276px;
  max-width: 220px;
}

.processIcon {
  background-color: var(--mainBrown);
  width: 30px;
  height: 30px;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  line-height: 30px;
  margin: 15px;
}

.cardSection {
  padding-top: 2%;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: bottom;
  background-color: #ffffff;
  height: fit-content;
}

.onboardingSection {
  padding-top: 2%;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: bottom;
  background-color: #ffffff;
  height: fit-content;
}

@media (max-width: 700px) {
  .onboardingSection {
    padding-top: 7%;
  }
}

.cardDescriptionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 90%;
  padding-top: 30px;
  padding-bottom: 50px;
}

.card {
  width: 300px;
  height: 300px;

  border-radius: 15px;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
  margin: 2%;
}

@media (max-width: 920px) {
  .card {
    width: 200px;
    padding-left: 0px;
    padding-bottom: 20px;
    padding-right: 0px;
    height: 300px;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 420px) {
  .card {
    max-width: 200px;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-right: 10px;
    height: 400px;
  }
}

.cardDescription{
  font-size: 18px;
  padding-top: 10px;
}



.DSIcon {
  max-height: 61px;
  max-width: 61px;
}

.woolitShopSection {
  max-height: 851px;
  padding: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #f27900;
  background-image: url("../../Visualizing/LandingPage/yarnString2.png");
  background-repeat: no-repeat;
  background-position: bottom 0px right 0px;
}

@media (max-width: 1282px) {
  .verticalLine {
    display: none;
  }
}

.shopPictureContainer {
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: auto;
}

.topImage {
  z-index: 2;

  height: auto;
  width: auto;
}

.bottomImage {
  margin-left: -100px;
  z-index: 1;
  height: auto;
  width: auto;
}

@media (max-width: 420px) {
  .topImage {
    height: 300px;
    width: auto;
  }

  .bottomImage {
    height: 300px;
    width: auto;
  }
}

.shopDescriptionContainer {
  color: var(--whiteish);
  list-style-image: url(../../Visualizing/LandingPage/check.svg);
  margin-top: 2%;
}

.endSection {
  display: flex;
  width: 83%;
  margin: 10%;
  height: 340px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  background-color: var(--whiteish);
}

.ctaButton {
  background-size: cover;
  background-image: url("yarn_brown.png");
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 36px;
  padding-left: 7%;
  padding-right: 7%;
  margin-top: 3%;
  margin-bottom: 3%;
  max-width: fit-content;
}

@media (max-width: 700px) {
  .ctaButton {
    
  padding-top: 4%;
  padding-bottom: 4%;
  border-radius: 36px;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 5%;
  margin-bottom: 5%;

  font-size: 20px;
  }
}

@media (max-width: 1000px) {
  .endSection {
    flex-direction: column;
    height: 100%;
    padding-top: 30px;
  }
}

.endSectionContent {
  display: flex;
  flex-direction: column;
  margin: 30px;
  height: 55%;
  justify-content: space-around;
}

@media (max-width: 1000px) {
  .endSectionContent {
    margin-bottom: 10%;
    margin-top: 10%;
  }
}

.pictureContainer {
  position: relative;
  max-width: 460px;
  height: 340px;
}

.sidebar {
  margin: 0.5rem;
  background: var(--lightGreen);
}

@media (min-width: 700px) {
  .sidebar {
    display: none;
  }
}
