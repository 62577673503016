.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container > img {
  width: 2000%;
  height: 2000%;
  filter: blur(100px);
  position: relative;
  left: -950%;
  top: -950%;
}
