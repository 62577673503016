.input {
  width: 76px;
  padding: 8px 5px 6px 5px;
  box-sizing: border-box;
  border: none;
  background: none;
  text-align: center;
  border-radius: 2px;
  outline: none;

  font-size: 14px;
  line-height: 20px;
}

.input:hover {
  border: 1px solid var(--neutral-40);
  padding: 7px 4px 5px 4px;
}

.input:focus {
  border: 1px solid var(--darkest-brown);
  padding: 7px 4px 5px 4px;
}

.input.locked {
  border: none;
  font-weight: bold;
  padding: 8px 5px 6px 5px;
}

.changed {
  width: 76px;
  padding: 8px 5px 6px 5px;
  box-sizing: border-box;
  border: none;
  text-align: center;
  border-radius: 2px;
  outline: none;

  font-size: 14px;
  line-height: 20px;
  color: var(--contrast);
  background-color: #fae6d9;
}

.changed:hover {
  border: 1px solid var(--contrast);
  padding: 7px 4px 5px 4px;
  background-color: #fae6d9;
}

.changes {
  display: flex;
}

.choice {
  width: 76px;
  padding: 8px 5px 6px 5px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 2px;
  outline: none;

  font-size: 14px;
  line-height: 20px;
}

.choice + *,
.changed + * {
  margin-left: 8px;
}
