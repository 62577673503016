.clickableDivider {
  position: absolute;
  height: 16px;
  width: 100%;
  bottom: -8px;
  cursor: pointer;
}

.clickableDivider:not(:hover) .newButton,
.clickableDivider:not(:hover) .dividerLine {
  opacity: 0;
}

.dividerLine {
  border: 1px solid var(--positive);
  margin-top: 8px;
}

.newButton {
  position: absolute;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  padding: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  bottom: -8px;
  left: calc(50% - 16px);
}

.newButton:hover {
  border-color: var(--positive);
  background: white;
}
