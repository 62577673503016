.wrapper {
  padding: 24px 36px;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
}

.formElement {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.formElement > label {
  font-size: 12px;
  color: var(--dark-brown);
}

.buttonContainerSingle {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.buttonContainerDouble {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.errorMsg {
  color: var(--negative);
}
