.table {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  margin: 24px 0;
}

.table + .table {
  margin-top: 192px;
}

.addRelation {
  margin: 24px 0 24px 10vw;
  width: 200px;
}
