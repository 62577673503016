.wrapper {
  max-width: 660px;
  width: 100%;
  min-width: 660px;
  padding: 40px;
}

.variantRow {
  display: flex;
  padding: 4px;
  align-items: center;
}

.firstCol {
  width: 50%;
  margin-right: 12px;
}

.secondCol {
  width: 50%;
  margin-left: 12px;
}

.activeSize {
  border-color: var(--positive);
  color: var(--positive);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomButton {
  text-align: right;
}

.button {
  padding: 16px;
  font-size: 18px;
  margin: 4px;
  letter-spacing: 2% !important;
  font-weight: 400;
}

.variantItem {
  display: flex;
  padding: 2px 8px;
  align-items: center;
}

.variantItem:hover {
  background: var(--neutral-10);
  cursor: pointer;
}

.colorGroup {
  grid-column: variant;
  display: flex;
  flex-wrap: wrap;

  min-width: 30px;
  max-width: 30px;
}

.color {
  margin: 1px;
  border-radius: 2px;
}

.vl {
  height: 20px;
  border-left: 1px solid var(--neutral-30);
  margin-right: 16px;
  margin-left: 16px;
}

.border {
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
}

.options {
  margin-top: 6px;
  border-radius: 2px;
  border: 1px solid var(--neutral-30);
  position: absolute;
  background: white;
  z-index: 2;
}

.yarnAmountTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yarnColor {
  display: flex;
  align-items: center;
}

.yarnColor > p {
  margin: 0 8px;
}

.yarnAmountRow {
  display: flex;
  justify-content: space-between;
}

.yarnAmount {
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
  padding: 4px 16px;
  margin: 0 8px;
  width: 70px;
  text-align: center;
}

.yarnAmountControl {
  display: flex;
}

.noteRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.noteRow > textarea {
  flex-grow: 2;
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
}

.noteRow > h4 {
  flex-grow: 1;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  display: flex;
  align-items: center;
}

.submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deadline {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
}

.deadline > p {
  margin-right: 16px;
}

.deadline > input {
  color: var(--positive);
}

.priceInput {
  height: 26px;
  width: 64px;
  margin: 0 6px 0 12px;
}

.assignment {
  display: flex;
  align-items: center;
  width: 100%;
}

.knitterRow {
  display: flex;
  justify-content: space-between;
}

.knitterRow > div {
  width: 50%;
}

.listBoxOptions {
  background: white;
  list-style-type: none;
  padding-left: 0;
  border: 1px solid var(--neutral-30);
  border-radius: 3px;
  position: absolute;
  z-index: 5;
}

.listBoxOption {
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
}

.listBoxActive {
  background: var(--neutral-10);
}

.listBoxButton {
  min-width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nameContainer {
  flex-grow: 1;
}

.hr {
  margin: 24px 0;
  border-top: 1px solid var(--neutral-10);
}

.textarea {
  font-family: var(--header-font-family);
  font-size: 18px;
  min-height: 2em;
  border-bottom: 2px solid var(--neutral-40);
}

/* Assignment list */

.assignmentsGrid {
  display: grid;
  grid-template-columns: [created] auto [knitter] auto [variant]auto [size]auto [tags]auto [deadline] auto [options]auto;
  row-gap: 8px;
  margin: 8px 0;
}

.assignmentsGrid > div {
  border-left: 1px solid var(--neutral-30);
  padding-left: 8px;
  height: 24px;
  display: flex;
  align-items: center;
}

.created {
  grid-column: created;
  display: flex;
  align-items: center;
}

.knitter {
  grid-column: knitter;
}

.size {
  grid-column: size;
}
.tags {
  grid-column: tags;
}
.deadlineItem {
  grid-column: deadline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.assignmentOption {
  grid-column: options;
  justify-content: flex-end;
}
.assignmentOption > button {
  border: none;
}
