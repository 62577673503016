.popover {
  position: relative;
}

.btn {
  border: none;
  padding: 0;
  margin: 2px 4px;
}

btn:hover {
  background-color: var(--white);
}

.panel {
  max-width: 128px;
  min-width: 48px;
  font-size: 10px;
  top: -10px;
  
  border: 1px solid var(--neutral-10);
  position: absolute;
  background-color: white;
  padding: 4px
}
